@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-blog-post-card-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  dev-blog-post-card {
    .blog-post-card {
      .mat-mdc-card-actions {
        .mat-mdc-button {
          color: if($is-dark, #91e5da, map.get($primary, 800));
        }
      }

      .image-container .categories .category {
        background-color: if($is-dark, map.get(mat.$m2-grey-palette, 800), map.get(mat.$m2-grey-palette, 300));
      }
    }
  }
}
