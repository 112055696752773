/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */

/*
 * Sass mixins
 */

// Generate min-width breakpoint. Mobile first, Preferred!
@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content;
  }
}
// Generate max-width breakpoint. Use sparingly!
@mixin breakpoint-max($point) {
  @media (max-width: $point) {
    @content;
  }
}
