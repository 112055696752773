@use 'sass:map';

@mixin dev-contact-info-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);

  dev-contact-info {
    #phone,
    #email-address,
    #address {
      a {
        color: map.get($foreground, text);

        &:hover {
          color: if($is-dark, map.get($primary, 100), map.get($primary, 400));
        }
      }
    }
  }
}
