@use '@angular/material' as mat;
@use 'sass:map';

@mixin careers-form-theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $is-dark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);

  .spinner-container mat-spinner circle {
    stroke: map.get($primary, if($is-dark, A100, A700));
  }

  .actions-container {
    .success-icon {
      color: if(
        $is-dark,
        mat.m2-get-color-from-palette(mat.$m2-green-palette, A700),
        mat.m2-get-color-from-palette(mat.$m2-green-palette, 800)
      );
    }

    .submitting-error-container {
      color: map.get($warn, A100), map.get($warn, A700);
    }

    .submit-button {
      background-color: map.get($primary, if($is-dark, A100, 600));
      color: if($is-dark, map.get(mat.$m2-light-theme-foreground-palette, text), map.get(mat.$m2-dark-theme-foreground-palette, text));
    }
  }

  input:-webkit-autofill,
  input:autofill {
    box-shadow: 0 0 0 30px if($is-dark, map.get($primary, A900), rgba(193, 208, 254, 0)) inset;
    -webkit-text-fill-color: map.get($foreground, text);
    caret-color: map.get($foreground, text);
  }
}
