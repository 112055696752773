@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-careers-landing-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  dev-careers-landing {
    section.section {
      .section-container {
        .headers {
          .title {
            color: if($is-dark, map.get($primary, A100), map.get($primary, 600));
          }
        }

        .job .job-link {
          color: map.get($foreground, text);
        }
      }
      &#hero {
        background-color: map.get($background, background);

        .hero-headers {
          .hero-title {
            color: map.get($primary, if($is-dark, A100, 600));
          }

          .hero-description {
            color: map.get($foreground, text);
          }

          #hero-button {
            color: map.get($primary, if($is-dark, A100, 800));
            border: 1px solid map.get($primary, if($is-dark, A100, 800));
          }
        }
      }
      &#our-values {
        background: map.get($primary, if($is-dark, A900, 50));

        .our-values-container .value .value-content .value-title {
          color: if($is-dark, map.get($foreground, text), map.get($primary, 700));
        }

        .our-values-container .value .value-content p,
        .our-values-container .value .value-icon {
          color: map.get(mat.$m2-grey-palette, if($is-dark, 200, 800));
        }

        .our-values-container .value .value-content .mat-divider {
          border-top-color: map.get(mat.$m2-grey-palette, if($is-dark, 400, 700));
        }
      }
      &#job-interest {
        background-color: map.get($primary, if($is-dark, A900, 50));
      }
    }
  }
}
