@use '@angular/material' as mat;
@use 'sass:map';
@use 'styles/mixins/careers-form.theme' as careers-form;

@mixin dev-job-application-form-theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $is-dark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);

  dev-job-application-form {
    @include careers-form.careers-form-theme($theme);

    .headers h2 {
      color: map.get($primary, if($is-dark, A100, 600));
    }

    .apply-again-container .apply-again-button {
      background-color: map.get($primary, if($is-dark, A100, 600));
      color: if($is-dark, map.get(mat.$m2-light-theme-foreground-palette, text), map.get(mat.$m2-dark-theme-foreground-palette, text));
    }
  }
}
