@use '@angular/material' as mat;
@use 'sass:map';

@mixin mat-select-custom-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);

  .dev-select.mat-mdc-select-panel {
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
      color: map.get($primary, if($is-dark, A100, 700));
    }
  }
}
