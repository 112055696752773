/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use 'sass:map';

@mixin dev-page-not-found-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);

  dev-page-not-found {
    button:hover {
      background-color: map.get($primary, A700);
    }
  }
}
