/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use 'sass:map';

@mixin dev-article-content-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $exportBackgroundOpacity: if($is-dark, 0.06, 0.03);

  dev-article-content {
    code {
      background: rgba(map.get($foreground, secondary-text), $exportBackgroundOpacity);
    }
  }
}
