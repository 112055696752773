@use '@angular/material' as mat;
@use 'sass:map';

@mixin mat-snackbar-custom-theme($theme) {
  $accent: map.get($theme, accent);
  $is-dark: map.get($theme, is-dark);

  .mat-mdc-snack-bar-container.dev-snackbar .mdc-snackbar__surface {
    background: mat.m2-get-color-from-palette($accent, A100);
    color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 900);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }
}
