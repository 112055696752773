@use '@angular/material' as mat;
@use 'sass:map';

@mixin mat-form-field-custom-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $warn: map.get($theme, warn);
  $foreground: map.get($theme, foreground);
  $form-field-color: map.get($primary, if($is-dark, A100, A700));

  .careers-form {
    .mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-field-color;
      }

      .mdc-floating-label {
        color: $form-field-color;
      }

      .mdc-text-field .mdc-text-field__input {
        caret-color: map.get($foreground, text);
      }
    }
  }
}
