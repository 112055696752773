@use 'sass:map';

@mixin dev-job-posting-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $application-form-background: if($is-dark, map.get($primary, A900), rgba(193, 208, 254, 0.3));

  dev-job-posting {
    section.job-application-form {
      background-color: $application-form-background;
    }
  }
}
