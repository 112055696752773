@use '@angular/material' as mat;
@use 'sass:map';

@mixin mat-tooltip-custom-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);

  .mat-mdc-tooltip-panel .mdc-tooltip__surface {
    background-color: if($is-dark, map.get($primary, A200), map.get(mat.$m2-grey-palette, 300));
    color: map.get(mat.$m2-grey-palette, 900);
  }
}
