/*!
 * Copyright 2023 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-angularjs-landing-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  dev-angularjs-landing {
    section.section {
      .section-container {
        .headers {
          .title {
            color: if($is-dark, map.get($primary, A100), map.get($primary, 600));
          }
        }
        .get-started-button,
        .reach-out-button {
          color: if($is-dark, map.get(mat.$m2-light-theme-foreground-palette, text), map.get(mat.$m2-dark-theme-foreground-palette, text));
          background-color: if($is-dark, map.get($primary, A200), map.get($primary, 600));
        }
      }
      &#hero {
        background-color: map.get($background, background);

        .hero-headers {
          .hero-title {
            color: if($is-dark, map.get($primary, A100), map.get($primary, 600));
          }

          .hero-description {
            color: map.get($foreground, text);
          }
        }
        .service-cards-container {
          .service-card {
            border: 1px solid if($is-dark, #636363, rgba(0, 0, 0, 0.2));
            background-color: map.get($background, background);
            font-family: inherit;

            .mat-card-content {
              color: if($is-dark, map.get(mat.$m2-grey-palette, A200), map.get(mat.$m2-grey-palette, 800));
            }
          }
        }
      }
      &#why-migrate {
        background: if($is-dark, map.get($primary, A900), rgba(193, 208, 254, 0.3));

        .why-migrate-features-container .feature .feature-content .feature-title {
          color: if($is-dark, map.get($foreground, text), map.get($primary, 600));
        }

        .why-migrate-features-container .feature .mat-divider {
          border-top-color: if($is-dark, map.get($foreground, text), map.get(mat.$m2-grey-palette, 700));
        }
      }
      &#how-we-can-help {
        background-color: map.get($background, background);

        .items-container .item-content {
          .item-title {
            color: if($is-dark, map.get($primary, A100), map.get($primary, 600));
          }
        }
      }
      &#lets-get-started {
        background: if($is-dark, map.get($primary, A900), map.get($primary, 50));
      }
      &#insights-from-the-experts {
        background-color: map.get($background, background);
      }
    }
  }
}
