/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use 'sass:map';
@use '@angular/material' as mat;

@mixin dev-blog-landing-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  dev-landing {
    .header {
      h1 {
        color: map.get($primary, if($is-dark, A100, 600));
      }
    }

    .mat-mdc-paginator {
      background-color: map.get($background, background);
      color: map.get($foreground, text);

      .mat-form-field-appearance-outline.mat-focused {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: map.get($primary, if($is-dark, A100, A700));
        }

        .mat-mdc-select-arrow {
          color: map.get($primary, if($is-dark, A100, A700));
        }
      }

      .mat-mdc-paginator-page-size,
      .mat-mdc-paginator-range-actions {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
}
