.rss-menu {
  width: 150px;
  margin-left: -65px;

  .mat-mdc-menu-content .rss-button-option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mat-icon {
      margin: 0;
    }
  }
}
