/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';

@mixin dev-footer-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $github-icon-color: if($is-dark, #ffffff, #000000);
  $linkedin-icon-color: if($is-dark, #66a5c8, #0072b1);
  $twitter-icon-color: if($is-dark, #90d0f8, #1da1f2);

  dev-footer {
    footer {
      background-color: if($is-dark, map.get(mat.$m2-grey-palette, 900), map.get($primary, 100));
    }
    .links {
      a {
        color: map.get($foreground, text);

        &:hover {
          color: if($is-dark, map.get($primary, 100), map.get($primary, 400));
        }
      }
    }
    .links #social-links {
      #github-icon {
        color: $github-icon-color;
      }

      #twitter-icon {
        color: $twitter-icon-color;
      }

      #linkedin-icon {
        color: $linkedin-icon-color;
      }
    }
  }
}
