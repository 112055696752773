/*!
* Copyright 2020 DevIntent - All Rights Reserved
*
* Unauthorized copying of this file, via any medium is strictly prohibited
* without the express permission of DevIntent.
*
* No warranty, explicit or implicit, provided.
* In no event shall DevIntent be liable for any claim or damages related to this code.
*/
@use 'sass:map';

@mixin dev-author-theme($theme) {
  $accent: map.get($theme, accent);
  $is-dark: map.get($theme, is-dark);

  dev-author {
    .author-card {
      background: none;
    }
    .updated-date {
      color: map.get($accent, if($is-dark, A200, 800));
    }
  }
}
