@use 'sass:map';

@mixin dev-job-posting-content-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);

  dev-job-posting-content {
    h1,
    h2,
    h3 {
      color: map.get($primary, if($is-dark, A100, 600));
    }
  }
}
