.category-chips {
  .mdc-evolution-chip-set__chips {
    display: flex;
    justify-content: center;
  }

  .category-chip button.mat-mdc-chip-action .mat-mdc-chip-action-label {
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
}
