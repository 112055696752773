@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-category-filter-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);
  $chip-selected-color: if($is-dark, map.get($foreground, text), map.get($primary, 900));
  $chip-overlay-background: if($is-dark, white, map.get($primary, 400));
  $form-field-color: map.get($primary, if($is-dark, A100, A700));

  dev-category-filter {
    .category-chips {
      .mat-mdc-chip.category-chip {
        background-color: if($is-dark, map.get($accent, A900), map.get(mat.$m2-grey-palette, 300));
        font-size: 16px;
        cursor: pointer;

        &::after {
          opacity: 0;
          background: $chip-overlay-background;
        }

        &.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary {
          background-color: if($is-dark, map.get($accent, A900), map.get(mat.$m2-grey-palette, 300));
          color: $chip-selected-color;

          .mat-icon {
            color: if($is-dark, map.get($foreground, text), map.get($primary, 900));
            opacity: 1;
          }

          &::after {
            opacity: if($is-dark, 0.08, 0.12);
          }
        }

        &:hover::after {
          opacity: if($is-dark, 0.1, 0.04);
        }
      }
    }

    .category-mat-form-field.mat-form-field-appearance-outline.mat-focused {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-field-color;
      }

      .mdc-floating-label,
      .mat-mdc-select-arrow {
        color: $form-field-color;
      }
    }
  }
}
