@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-theme-toggle-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $border-color: map.get(mat.$m2-grey-palette, if($is-dark, 400, 700));

  dev-theme-toggle {
    .mat-button-toggle-group {
      border: solid 1px $border-color;

      .mat-button-toggle {
        background: transparent;
      }

      .mat-button-toggle-checked {
        background-color: if($is-dark, rgba(255, 255, 255, 0.08), map.get($primary, A200));
      }

      .mat-button-toggle + .mat-button-toggle {
        border-left: 1px solid $border-color;
      }
    }
  }
}
