@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-technology-cards($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $border-color-card: if($is-dark, map.get(mat.$m2-grey-palette, 700), rgba(0, 0, 0, 0.2));

  dev-technology-cards {
    .technology-cards-container {
      .technology-card {
        border: 1px solid $border-color-card;
        background-color: map.get($background, background);

        .mat-card-header-text {
          margin: 0;
        }

        .mat-card-content {
          color: map.get($foreground, text);
        }
      }
    }
  }
}
