@use 'sass:map';
@use '@angular/material' as mat;

@mixin dev-rss-button-theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);

  dev-rss-button {
    .mat-mdc-button.rss-button:not(:disabled) {
      color: map-get($primary, if($is-dark, A100, 800));
    }
  }

  .rss-menu {
    .mat-menu-content,
    .rss-button-option {
      background: if($is-dark, map-get(mat.$m2-grey-palette, 800), white);
      color: map.get($foreground, text);
    }
  }
}
