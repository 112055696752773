/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use '@angular/material' as mat;
@use 'sass:map';
@use 'variables';

@mixin dev-nav-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $focused-item-background: if($is-dark, map.get($background, focused-button), #ebebeb);
  $hovered-item-background: if($is-dark, map.get($background, hover), #f6f6f6);

  dev-nav {
    .mat-sidenav.sidenav {
      .mat-mdc-nav-list {
        .mat-mdc-list-item {
          &.active:hover::before {
            background-color: transparent;
          }
          .mat-mdc-list-item-icon,
          .mat-mdc-list-item-title {
            color: map.get($foreground, text);
          }
        }
      }
      .mat-mdc-nav-list:not([dense]) {
        .mat-mdc-list-item {
          &.active {
            background-color: if($is-dark, rgba(map.get($background, background), 0.87), variables.$devintent-background-blue);

            .mat-mdc-list-item-icon,
            .mat-mdc-list-item-title {
              color: map.get($primary, if($is-dark, A100, A700));
            }
          }
        }
      }
    }
    .mat-sidenav-content {
      .mat-toolbar.header {
        .mat-mdc-stroked-button,
        .mat-mdc-button,
        .mat-mdc-raised-button {
          color: map.get($primary, if($is-dark, A100, A700));

          &.mat-primary {
            color: variables.$light-text-color;
          }
        }

        .contact-button {
          color: if($is-dark, map.get(mat.$m2-light-theme-foreground-palette, text), map.get(mat.$m2-dark-theme-foreground-palette, text));
          background-color: if($is-dark, map.get($primary, A200), map.get($primary, 600));
        }
      }
    }
  }
}
