@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-article-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $is-dark: map.get($theme, is-dark);

  dev-article {
    main.blog-article {
      .back-to-blog-link {
        color: map.get($primary, if($is-dark, A200, 800));
      }

      .categories .mat-mdc-chip.category-chip {
        background-color: if($is-dark, map.get($accent, A900), map.get(mat.$m2-grey-palette, 300));

        .mat-mdc-chip-action-label {
          font-size: 16px;
        }
      }
    }
  }
}
