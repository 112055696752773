@use '@angular/material' as mat;
@use 'sass:map';

@mixin mat-card-custom-theme($theme) {
  $is-dark: map.get($theme, is-dark);

  mat-card.mat-mdc-card {
    @include mat.elevation-transition(300);
    @include mat.elevation(1);

    &:hover {
      @include mat.elevation(if($is-dark, 8, 4));
    }

    &:focus {
      @include mat.elevation(0);
    }
  }
}
