@use 'sass:map';
@use '@angular/material' as mat;

@mixin dev-thank-you-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  dev-thank-you {
    .hero-header h3 {
      color: map.get($primary, if($is-dark, A100, 600));
    }

    .error-message-container {
      border: 1px solid map.get(mat.$m2-grey-palette, if($is-dark, 50, 400));

      #error-icon,
      .error-message > p > span {
        color: map.get($warn, if($is-dark, 200, 900));
      }
    }
  }
}
