/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use '@angular/material' as mat;
@use 'sass:map';
@use 'variables';
@use 'mixins';

@mixin dev-about-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $world-renowned-experts-background: if(
    $is-dark,
    linear-gradient(180deg, map.get($primary, A900) 0%, map.get($background, background) 100%),
    linear-gradient(180deg, map.get($primary, 50) 0%, rgba(255, 255, 255, 0) 100%)
  );
  $ready-to-get-started-background: if($is-dark, map.get($primary, A900), linear-gradient(281.01deg, #91e5da 0%, #0040f0 63.1%));
  $hero-banner-background: if($is-dark, map.get($background, background), linear-gradient(281.01deg, #91e5da 0%, #0040f0 63.1%));

  dev-about {
    .section {
      .section-container {
        .headers {
          .title {
            color: if($is-dark, map.get($primary, A100), map.get($primary, 800));
          }
        }
      }
      &#hero {
        background: $hero-banner-background;

        .hero-headers {
          .hero-title {
            color: if($is-dark, map.get($primary, A100), white);
          }

          .hero-description {
            color: if($is-dark, map.get($foreground, text), white);
          }

          .get-started-button {
            color: if($is-dark, map.get(mat.$m2-light-theme-foreground-palette, text), map.get($primary, 700));
            background-color: if($is-dark, map.get($primary, A200), map.get(mat.$m2-dark-theme-foreground-palette, text));
          }
        }
      }
      &#how-we-can-help {
        background-color: if($is-dark, map.get($primary, A900), map.get($primary, 50));

        .item .content {
          h4 {
            color: if($is-dark, map.get($foreground, text), map.get($primary, 800));
          }

          .mat-divider {
            background-color: if($is-dark, map.get($foreground, text), map.get(mat.$m2-grey-palette, 700));
          }
        }
      }
      &#what-we-do-best {
        .headers .get-started-button {
          color: if($is-dark, map.get(mat.$m2-light-theme-foreground-palette, text), map.get(mat.$m2-dark-theme-foreground-palette, text));
          background-color: if($is-dark, map.get($primary, A200), map.get($primary, 600));
        }
      }
      &#world-renowned-experts {
        background: $world-renowned-experts-background;

        .headers #meet-the-team-button:not([disabled]) {
          color: if($is-dark, map.get($primary, A100), map.get($primary, 800));
        }
      }
      &#ready-to-get-started {
        background: $ready-to-get-started-background;

        .section-container .headers .title {
          color: map.get(mat.$m2-gray-palette, 50);
        }

        .reach-out-button {
          color: if($is-dark, map.get($primary, A900), map.get($primary, 700));
          background-color: if($is-dark, map.get($accent, 300), variables.$light-background);
        }
      }
    }
  }
}
