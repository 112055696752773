/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use '@angular/material' as mat;
@use 'sass:map';

@mixin dev-team-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $focused-item-background: if($is-dark, map.get($background, focused-button), #ebebeb);
  $hovered-item-background: if($is-dark, map.get($background, hover), #f6f6f6);
  $linkedin-icon-color: if($is-dark, #66a5c8, #0072b1);
  $twitter-icon-color: if($is-dark, #90d0f8, #1da1f2);

  dev-team {
    main.team-container {
      .mat-mdc-card-title,
      .mat-mdc-card-header-text {
        margin: 0;
      }

      .header {
        h1,
        h2 {
          color: map.get($primary, if($is-dark, A100, 600));
        }
      }

      #twitter-icon {
        color: $twitter-icon-color;
      }

      #linkedin-icon {
        color: $linkedin-icon-color;
      }
    }
  }
}
