/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */

// Breakpoints
$handset: 360px;
$large-handset: 480px;
$x-large-handset: 600px; // sm
$tablet: 640px; /* app drawer breakpoint */
$large-tablet: 840px;
$desktop: 960px; // md
$large-desktop: 1280px; // lg
$x-large-desktop: 1440px;
$xx-large-desktop: 1920px; // xl
$ultra-hd-desktop: 2560px;

$article-width: 700px;

// Non-theme based colors
$devintent-blue: #3366ff;
$devintent-grey: #777777; // Do not use for text as contrast on white is too low for a11y. See $dark-text-color.
$devintent-background-blue: rgba(51, 102, 255, 0.12);

$light-background: white;
$light-text-color: #e8eaf6;

// Fonts
$roboto: "Roboto, 'Helvenita Neue', sans-serif";
$roboto-slab: "'Roboto Slab', serif";
$work-sans: "'Work Sans', sans-serif";
