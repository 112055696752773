@use 'sass:map';
@use '@angular/material' as mat;

@mixin dev-contact-form-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $form-field-color: map.get($primary, if($is-dark, A100, A700));

  dev-contact-form {
    .hero-header {
      h1 {
        color: map.get($primary, if($is-dark, A100, 600));
      }
    }

    .mat-mdc-form-field.mat-mdc-form-field-invalid {
      .mat-mdc-form-field-label {
        color: map.get($warn, text);
      }
    }

    .mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-field-color;
      }

      .mdc-floating-label {
        color: $form-field-color;
      }

      .mdc-text-field .mdc-text-field__input {
        caret-color: map.get($foreground, text);
      }
    }

    .actions-container {
      .mat-icon {
        color: if(
          $is-dark,
          mat.m2-get-color-from-palette(mat.$m2-green-palette, A700),
          mat.m2-get-color-from-palette(mat.$m2-green-palette, 800)
        );
      }

      .submitting-error {
        color: if($is-dark, map.get($warn, A400), map.get($warn, A700));
      }
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: map.get($primary, if($is-dark, 50, A300));
  }

  input:-webkit-autofill,
  input:autofill {
    box-shadow: 0 0 0 30px map.get($background, background) inset;
    -webkit-text-fill-color: map.get($foreground, text);
    caret-color: map.get($foreground, text);
  }
}
