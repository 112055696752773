@use '@angular/material' as mat;
@use 'sass:map';

@mixin _overlay-button-variant($background, $opacity) {
  .mat-mdc-button-persistent-ripple::before {
    opacity: $opacity;
    background-color: $background;
  }
}

@mixin mat-button-custom-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $foreground: map.get($theme, foreground);
  $is-dark: map.get($theme, is-dark);
  $primary-overlay-base-color: rgb(51, 102, 255);
  $accent-overlay-base-color: rgb(145, 229, 218);
  $white-overlay-base-color: rgb(255, 255, 255);
  $black-overlay-base-color: rgb(0, 0, 0);

  // Styles for buttons base
  .mdc-button.mat-mdc-button-base {
    @include _overlay-button-variant($white-overlay-base-color, 0);

    &.mat-primary {
      &:hover {
        color: map.get($primary, if($is-dark, 50, 600));
      }

      &:active,
      &:focus {
        color: map.get($primary, if($is-dark, 100, 400));
      }
    }

    &.mat-accent {
      &:hover {
        color: map.get($accent, if($is-dark, 300, 800));
      }

      &:active,
      &:focus {
        color: map.get($accent, if($is-dark, 400, 600));
      }
    }

    &[disabled] {
      color: rgba($black-overlay-base-color, 0.38);
    }
  }

  // Styles for outlined buttons / stroked buttons
  .mdc-button.mat-mdc-outlined-button {
    &.mat-primary {
      &:hover {
        @if $is-dark {
          @include _overlay-button-variant($white-overlay-base-color, 0.1);
        } @else {
          @include _overlay-button-variant($primary-overlay-base-color, 0.04);
        }
      }

      &:active,
      &:focus {
        @if $is-dark {
          @include _overlay-button-variant($white-overlay-base-color, 0.1);
        } @else {
          @include _overlay-button-variant($primary-overlay-base-color, 0.2);
        }
      }
    }

    &.mat-accent {
      &:hover {
        @include _overlay-button-variant($accent-overlay-base-color, 0.1);
      }

      &:active,
      &:focus {
        @include _overlay-button-variant($accent-overlay-base-color, 0.2);
      }
    }

    &[disabled] {
      color: rgba($black-overlay-base-color, 0.38);
      border: 1px solid rgba($black-overlay-base-color, 0.38);
    }
  }

  // Styles for raised buttons
  .mdc-button.mat-mdc-raised-button {
    &.mat-primary,
    &.mat-accent {
      &:hover {
        @include _overlay-button-variant($white-overlay-base-color, 0.1);
      }

      &:active,
      &:focus {
        @include _overlay-button-variant($white-overlay-base-color, 0.2);
      }

      &[disabled] {
        @include _overlay-button-variant($white-overlay-base-color, 0.38);
      }
    }

    &.mat-unthemed[disabled] {
      @include _overlay-button-variant($white-overlay-base-color, 0.38);
    }
  }

  // Shared styles
  .mdc-button.mat-mdc-outlined-button,
  .mdc-button.mat-mdc-raised-button {
    &.mat-unthemed {
      &:hover {
        @include _overlay-button-variant($black-overlay-base-color, 0.04);
      }

      &:active,
      &:focus {
        @include _overlay-button-variant($black-overlay-base-color, 0.06);
      }
    }
  }
}
